import { UseFetchOptions } from "#app";
import { WithRequired } from "~/types/types";

interface PageQueryVariableOptions {
  [key: string]: string | PageQueryVariableOptions;
}
interface UsePageQueryReturn<T> {
  data: T;
}

/**
 *
 * @description test
 * @param variables
 * @param options
 */
export const usePageQuery = async <T>(
  query: string,
  variables: PageQueryVariableOptions = {},
  options: WithRequired<
    Omit<
      UseFetchOptions<UsePageQueryReturn<T>>,
      "method" | "headers" | "body" | "getCachedData"
    >,
    "key"
  >
) => {
  // backup data key, manually setting a key would be better but this makes sure we're keying by default
  const { data } = await useFetch<UsePageQueryReturn<T>, Error>(
    "/api/graphql",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
      deep: false,
      ...options,
      getCachedData(key, nuxtApp) {
        return nuxtApp.payload.data[key] || nuxtApp.static.data[key];
      },
    }
  );
  return data.value?.data;
};
