import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { useWindowSize } from "@vueuse/core";
import { useRfiService } from "~/composables/useRfiService";

declare global {
  interface Window {
    embedded_svc: any;
  }
}

declare let embedded_svc: any;
export const useSalesForceService = () => {
  const RfiService = useRfiService();
  const config = useRuntimeConfig();
  const { width: windowWidth } = useWindowSize();
  const isDesktopScreen = computed(() => {
    return windowWidth.value > 991 ? true : false;
  });

  function handleChatClick() {
    analyticsComposable.trackEvent({
      event: "link",
      name: "onclick",
      action: "click",
      type: "internal link",
      region: "main content",
      section: "sticky icon",
      text: "chat with us",
    });
  }

  const initESW = (gslbBaseURL?: string, isDesktopScreen = false) => {
    if (process.browser) {
      embedded_svc.settings.displayHelpButton = false; // default hidden; overridden in css as needed
      embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'
      embedded_svc.settings.defaultMinimizedText = "Chat with us";

      // SF pre-chat form details
      // send GA client ID and Referrer URI
      embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: "Google_Client_Id__c",
          value: RfiService.getGaClientId(),
          displayToAgent: true,
          transcriptFields: ["Google_Client_Id__c"],
        },
        {
          label: "ReferrerUri",
          value: process.browser ? window.location.href : "",
          displayToAgent: true,
          transcriptFields: ["ReferrerUri"],
        },
      ];

      // set up chat opening/closing event handling
      embedded_svc.addEventHandler("onHelpButtonClick", () => {
        handleChatClick();
      }); // initial chat window open

      if (config.public.siteEnv == "prod") {
        embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        embedded_svc.settings.entryFeature = "LiveAgent";

        try {
          embedded_svc.init(
            "https://edplus-asu.my.salesforce.com",
            "https://edplus-asu.my.site.com",
            gslbBaseURL,
            "00D41000002vSQx",
            "ASU_Online_Chatbot",
            {
              baseLiveAgentContentURL:
                "https://c.la4-c1-ia4.salesforceliveagent.com/content",
              deploymentId: "5726S000000AypB",
              buttonId: "5736S000000B2m4",
              baseLiveAgentURL:
                "https://d.la4-c1-ia4.salesforceliveagent.com/chat",
              eswLiveAgentDevName:
                "EmbeddedServiceLiveAgent_Parent04I6S0000008ObBUAU_183ca2e24fa",
              isOfflineSupportEnabled: false,
            }
          );
        } catch (e) {
          console.error("issue initializing chatbot", e);
        }
      } else {
        console.log("Using Local/ Dev Chat Environment");
        embedded_svc.settings.displayHelpButton = true; //Or false
        embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        embedded_svc.settings.entryFeature = "LiveAgent";

        try {
          embedded_svc.init(
            "https://edplus-asu--qa.sandbox.my.salesforce.com",
            "https://edplus-asu--qa.sandbox.my.salesforce-sites.com/",
            gslbBaseURL,
            "00D6u0000000NH5",
            "ASU_Online_Chatbot",
            {
              baseLiveAgentContentURL:
                "https://c.la4-c1cs-ia4.salesforceliveagent.com/content",
              deploymentId: "5726S000000AypB",
              buttonId: "5736S000000B2m4",
              baseLiveAgentURL:
                "https://d.la4-c1cs-ia4.salesforceliveagent.com/chat",
              eswLiveAgentDevName:
                "EmbeddedServiceLiveAgent_Parent04I6S0000008ObBUAU_183ca2e24fa",
              isOfflineSupportEnabled: false,
            }
          );
        } catch (e) {
          console.error("issue initializing chatbot", e);
        }
      }
    }
  };

  useHead({
    script: [
      {
        src: "https://edplus-asu.my.salesforce.com/embeddedservice/5.0/esw.min.js",
        onload(e) {
          initESW(undefined, isDesktopScreen.value);
        },
        defer: true,
      },
    ],
  });
};
