import { default as _200paKZdRuNWOMeta } from "/opt/atlassian/pipelines/agent/build/pages/200.vue?macro=true";
import { default as _404VI8KNBPx5SMeta } from "/opt/atlassian/pipelines/agent/build/pages/404.vue?macro=true";
import { default as accreditationncvGMF3YLoMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/accreditation.vue?macro=true";
import { default as contact_45usWIobK7iqyvMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/contact-us.vue?macro=true";
import { default as facultyqhZcWBPrPqMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/faculty.vue?macro=true";
import { default as faqTEiuvXEnaBMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/faq.vue?macro=true";
import { default as indexEn3k1oizl4Meta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/index.vue?macro=true";
import { default as policiesilvdIPg6mMMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/policies.vue?macro=true";
import { default as rankingsmwslLd3TvgMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/rankings.vue?macro=true";
import { default as why_45asu_45onlineAh0GlleESqMeta } from "/opt/atlassian/pipelines/agent/build/pages/about-us/why-asu-online.vue?macro=true";
import { default as indexVkYwSs6xM8Meta } from "/opt/atlassian/pipelines/agent/build/pages/admission/[slug]/apply/index.vue?macro=true";
import { default as indexodjS0nLfuaMeta } from "/opt/atlassian/pipelines/agent/build/pages/admission/[slug]/index.vue?macro=true";
import { default as indexUZgHHzTPFfMeta } from "/opt/atlassian/pipelines/agent/build/pages/admission/index.vue?macro=true";
import { default as indexvkPiwG5KUMMeta } from "/opt/atlassian/pipelines/agent/build/pages/admission/nondegree/apply/index.vue?macro=true";
import { default as chat_45with_45asunZ1BqrVNM6Meta } from "/opt/atlassian/pipelines/agent/build/pages/chat-with-asu.vue?macro=true";
import { default as degree_45program_45request_45info_45thank_45youFLKRKouy79Meta } from "/opt/atlassian/pipelines/agent/build/pages/degree-program-request-info-thank-you.vue?macro=true";
import { default as first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta } from "/opt/atlassian/pipelines/agent/build/pages/first-time-freshman-registration-rules.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as join_45asu_45sun_45devilsqS4kBnhBdhMeta } from "/opt/atlassian/pipelines/agent/build/pages/join-asu-sun-devils.vue?macro=true";
import { default as military_45scholarshiporz9u4AoZ0Meta } from "/opt/atlassian/pipelines/agent/build/pages/military-scholarship.vue?macro=true";
import { default as af79FUmxM8cMeta } from "/opt/atlassian/pipelines/agent/build/pages/new/degree-program-request-info-thank-you/a.vue?macro=true";
import { default as bsqCXpXVkibMeta } from "/opt/atlassian/pipelines/agent/build/pages/new/degree-program-request-info-thank-you/b.vue?macro=true";
import { default as indexWyIKrXvTZHMeta } from "/opt/atlassian/pipelines/agent/build/pages/newsroom/[category]/[article]/index.vue?macro=true";
import { default as index1kj9M3jhXXMeta } from "/opt/atlassian/pipelines/agent/build/pages/newsroom/[category]/index.vue?macro=true";
import { default as index1F0OHapS4HMeta } from "/opt/atlassian/pipelines/agent/build/pages/newsroom/index.vue?macro=true";
import { default as _91slug_939U44iiLDFEMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/certificates/[slug].vue?macro=true";
import { default as indexkBwoa7e03LMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/certificates/index.vue?macro=true";
import { default as _91slug_93t39i6yPVrOMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/graduate/[slug].vue?macro=true";
import { default as indexcoRP5dFWQRMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/graduate/index.vue?macro=true";
import { default as indexqvAFqgMOE4Meta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/index.vue?macro=true";
import { default as indexRAQIgCd4IXMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/minors/index.vue?macro=true";
import { default as _91slug_93mLnLnl0iLrMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/undergraduate/[slug].vue?macro=true";
import { default as indexXLHckAJI6OMeta } from "/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/undergraduate/index.vue?macro=true";
import { default as communityArLvpvi0AdMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/community.vue?macro=true";
import { default as coursesmAzLjdNISUMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/courses.vue?macro=true";
import { default as graduation_45beyondCfMoXbzouOMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/graduation-beyond.vue?macro=true";
import { default as indexZEdnN4DVRLMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/index.vue?macro=true";
import { default as reviewsM0FNE2wRryMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/reviews.vue?macro=true";
import { default as servicesjRrcVCCfmBMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/services.vue?macro=true";
import { default as study_45abroadvq0nosFvNDMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/study-abroad.vue?macro=true";
import { default as success_45team0SUSxxyfhEMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/success-team.vue?macro=true";
import { default as tutoringv7JmwjBRHHMeta } from "/opt/atlassian/pipelines/agent/build/pages/students/tutoring.vue?macro=true";
import { default as _91slug_93HpEVrt151bMeta } from "/opt/atlassian/pipelines/agent/build/pages/study/[slug].vue?macro=true";
import { default as indexD41gimTwCOMeta } from "/opt/atlassian/pipelines/agent/build/pages/study/index.vue?macro=true";
import { default as text_45termseuFnxRyT4tMeta } from "/opt/atlassian/pipelines/agent/build/pages/text-terms.vue?macro=true";
import { default as web_45analytics_45privacy_452M9Mov08huLMeta } from "/opt/atlassian/pipelines/agent/build/pages/web-analytics-privacy-2.vue?macro=true";
import { default as indexxzSZRj4HB7Meta } from "/opt/atlassian/pipelines/agent/build/pages/what-it-costs/financial-aid/index.vue?macro=true";
import { default as indexFHOrMoN10kMeta } from "/opt/atlassian/pipelines/agent/build/pages/what-it-costs/index.vue?macro=true";
import { default as indexRjeqvkbJpmMeta } from "/opt/atlassian/pipelines/agent/build/pages/what-it-costs/scholarship-opportunities/index.vue?macro=true";
export default [
  {
    name: _200paKZdRuNWOMeta?.name ?? "200",
    path: _200paKZdRuNWOMeta?.path ?? "/200",
    meta: _200paKZdRuNWOMeta || {},
    alias: _200paKZdRuNWOMeta?.alias || [],
    redirect: _200paKZdRuNWOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/200.vue").then(m => m.default || m)
  },
  {
    name: _404VI8KNBPx5SMeta?.name ?? "404",
    path: _404VI8KNBPx5SMeta?.path ?? "/404",
    meta: _404VI8KNBPx5SMeta || {},
    alias: _404VI8KNBPx5SMeta?.alias || [],
    redirect: _404VI8KNBPx5SMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accreditationncvGMF3YLoMeta?.name ?? "about-us-accreditation",
    path: accreditationncvGMF3YLoMeta?.path ?? "/about-us/accreditation",
    meta: accreditationncvGMF3YLoMeta || {},
    alias: accreditationncvGMF3YLoMeta?.alias || [],
    redirect: accreditationncvGMF3YLoMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/accreditation.vue").then(m => m.default || m)
  },
  {
    name: contact_45usWIobK7iqyvMeta?.name ?? "about-us-contact-us",
    path: contact_45usWIobK7iqyvMeta?.path ?? "/about-us/contact-us",
    meta: contact_45usWIobK7iqyvMeta || {},
    alias: contact_45usWIobK7iqyvMeta?.alias || [],
    redirect: contact_45usWIobK7iqyvMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/contact-us.vue").then(m => m.default || m)
  },
  {
    name: facultyqhZcWBPrPqMeta?.name ?? "about-us-faculty",
    path: facultyqhZcWBPrPqMeta?.path ?? "/about-us/faculty",
    meta: facultyqhZcWBPrPqMeta || {},
    alias: facultyqhZcWBPrPqMeta?.alias || [],
    redirect: facultyqhZcWBPrPqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/faculty.vue").then(m => m.default || m)
  },
  {
    name: faqTEiuvXEnaBMeta?.name ?? "about-us-faq",
    path: faqTEiuvXEnaBMeta?.path ?? "/about-us/faq",
    meta: faqTEiuvXEnaBMeta || {},
    alias: faqTEiuvXEnaBMeta?.alias || [],
    redirect: faqTEiuvXEnaBMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/faq.vue").then(m => m.default || m)
  },
  {
    name: indexEn3k1oizl4Meta?.name ?? "about-us",
    path: indexEn3k1oizl4Meta?.path ?? "/about-us",
    meta: indexEn3k1oizl4Meta || {},
    alias: indexEn3k1oizl4Meta?.alias || [],
    redirect: indexEn3k1oizl4Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: policiesilvdIPg6mMMeta?.name ?? "about-us-policies",
    path: policiesilvdIPg6mMMeta?.path ?? "/about-us/policies",
    meta: policiesilvdIPg6mMMeta || {},
    alias: policiesilvdIPg6mMMeta?.alias || [],
    redirect: policiesilvdIPg6mMMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/policies.vue").then(m => m.default || m)
  },
  {
    name: rankingsmwslLd3TvgMeta?.name ?? "about-us-rankings",
    path: rankingsmwslLd3TvgMeta?.path ?? "/about-us/rankings",
    meta: rankingsmwslLd3TvgMeta || {},
    alias: rankingsmwslLd3TvgMeta?.alias || [],
    redirect: rankingsmwslLd3TvgMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/rankings.vue").then(m => m.default || m)
  },
  {
    name: why_45asu_45onlineAh0GlleESqMeta?.name ?? "about-us-why-asu-online",
    path: why_45asu_45onlineAh0GlleESqMeta?.path ?? "/about-us/why-asu-online",
    meta: why_45asu_45onlineAh0GlleESqMeta || {},
    alias: why_45asu_45onlineAh0GlleESqMeta?.alias || [],
    redirect: why_45asu_45onlineAh0GlleESqMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/about-us/why-asu-online.vue").then(m => m.default || m)
  },
  {
    name: indexVkYwSs6xM8Meta?.name ?? "admission-slug-apply",
    path: indexVkYwSs6xM8Meta?.path ?? "/admission/:slug()/apply",
    meta: indexVkYwSs6xM8Meta || {},
    alias: indexVkYwSs6xM8Meta?.alias || [],
    redirect: indexVkYwSs6xM8Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admission/[slug]/apply/index.vue").then(m => m.default || m)
  },
  {
    name: indexodjS0nLfuaMeta?.name ?? "admission-slug",
    path: indexodjS0nLfuaMeta?.path ?? "/admission/:slug()",
    meta: indexodjS0nLfuaMeta || {},
    alias: indexodjS0nLfuaMeta?.alias || [],
    redirect: indexodjS0nLfuaMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admission/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUZgHHzTPFfMeta?.name ?? "admission",
    path: indexUZgHHzTPFfMeta?.path ?? "/admission",
    meta: indexUZgHHzTPFfMeta || {},
    alias: indexUZgHHzTPFfMeta?.alias || [],
    redirect: indexUZgHHzTPFfMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admission/index.vue").then(m => m.default || m)
  },
  {
    name: indexvkPiwG5KUMMeta?.name ?? "admission-nondegree-apply",
    path: indexvkPiwG5KUMMeta?.path ?? "/admission/nondegree/apply",
    meta: indexvkPiwG5KUMMeta || {},
    alias: indexvkPiwG5KUMMeta?.alias || [],
    redirect: indexvkPiwG5KUMMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/admission/nondegree/apply/index.vue").then(m => m.default || m)
  },
  {
    name: chat_45with_45asunZ1BqrVNM6Meta?.name ?? "chat-with-asu",
    path: chat_45with_45asunZ1BqrVNM6Meta?.path ?? "/chat-with-asu",
    meta: chat_45with_45asunZ1BqrVNM6Meta || {},
    alias: chat_45with_45asunZ1BqrVNM6Meta?.alias || [],
    redirect: chat_45with_45asunZ1BqrVNM6Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/chat-with-asu.vue").then(m => m.default || m)
  },
  {
    name: degree_45program_45request_45info_45thank_45youFLKRKouy79Meta?.name ?? "degree-program-request-info-thank-you",
    path: degree_45program_45request_45info_45thank_45youFLKRKouy79Meta?.path ?? "/degree-program-request-info-thank-you",
    meta: degree_45program_45request_45info_45thank_45youFLKRKouy79Meta || {},
    alias: degree_45program_45request_45info_45thank_45youFLKRKouy79Meta?.alias || [],
    redirect: degree_45program_45request_45info_45thank_45youFLKRKouy79Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/degree-program-request-info-thank-you.vue").then(m => m.default || m)
  },
  {
    name: first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta?.name ?? "first-time-freshman-registration-rules",
    path: first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta?.path ?? "/first-time-freshman-registration-rules",
    meta: first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta || {},
    alias: first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta?.alias || [],
    redirect: first_45time_45freshman_45registration_45rulesFYw6DcWu7KMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/first-time-freshman-registration-rules.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: join_45asu_45sun_45devilsqS4kBnhBdhMeta?.name ?? "join-asu-sun-devils",
    path: join_45asu_45sun_45devilsqS4kBnhBdhMeta?.path ?? "/join-asu-sun-devils",
    meta: join_45asu_45sun_45devilsqS4kBnhBdhMeta || {},
    alias: join_45asu_45sun_45devilsqS4kBnhBdhMeta?.alias || [],
    redirect: join_45asu_45sun_45devilsqS4kBnhBdhMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/join-asu-sun-devils.vue").then(m => m.default || m)
  },
  {
    name: military_45scholarshiporz9u4AoZ0Meta?.name ?? "military-scholarship",
    path: military_45scholarshiporz9u4AoZ0Meta?.path ?? "/military-scholarship",
    meta: military_45scholarshiporz9u4AoZ0Meta || {},
    alias: military_45scholarshiporz9u4AoZ0Meta?.alias || [],
    redirect: military_45scholarshiporz9u4AoZ0Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/military-scholarship.vue").then(m => m.default || m)
  },
  {
    name: af79FUmxM8cMeta?.name ?? "new-degree-program-request-info-thank-you-a",
    path: af79FUmxM8cMeta?.path ?? "/new/degree-program-request-info-thank-you/a",
    meta: af79FUmxM8cMeta || {},
    alias: af79FUmxM8cMeta?.alias || [],
    redirect: af79FUmxM8cMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/new/degree-program-request-info-thank-you/a.vue").then(m => m.default || m)
  },
  {
    name: bsqCXpXVkibMeta?.name ?? "new-degree-program-request-info-thank-you-b",
    path: bsqCXpXVkibMeta?.path ?? "/new/degree-program-request-info-thank-you/b",
    meta: bsqCXpXVkibMeta || {},
    alias: bsqCXpXVkibMeta?.alias || [],
    redirect: bsqCXpXVkibMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/new/degree-program-request-info-thank-you/b.vue").then(m => m.default || m)
  },
  {
    name: indexWyIKrXvTZHMeta?.name ?? "newsroom-category-article",
    path: indexWyIKrXvTZHMeta?.path ?? "/newsroom/:category()/:article()",
    meta: indexWyIKrXvTZHMeta || {},
    alias: indexWyIKrXvTZHMeta?.alias || [],
    redirect: indexWyIKrXvTZHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/newsroom/[category]/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: index1kj9M3jhXXMeta?.name ?? "newsroom-category",
    path: index1kj9M3jhXXMeta?.path ?? "/newsroom/:category()",
    meta: index1kj9M3jhXXMeta || {},
    alias: index1kj9M3jhXXMeta?.alias || [],
    redirect: index1kj9M3jhXXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/newsroom/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index1F0OHapS4HMeta?.name ?? "newsroom",
    path: index1F0OHapS4HMeta?.path ?? "/newsroom",
    meta: index1F0OHapS4HMeta || {},
    alias: index1F0OHapS4HMeta?.alias || [],
    redirect: index1F0OHapS4HMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939U44iiLDFEMeta?.name ?? "online-degree-programs-certificates-slug",
    path: _91slug_939U44iiLDFEMeta?.path ?? "/online-degree-programs/certificates/:slug()",
    meta: _91slug_939U44iiLDFEMeta || {},
    alias: _91slug_939U44iiLDFEMeta?.alias || [],
    redirect: _91slug_939U44iiLDFEMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/certificates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexkBwoa7e03LMeta?.name ?? "online-degree-programs-certificates",
    path: indexkBwoa7e03LMeta?.path ?? "/online-degree-programs/certificates",
    meta: indexkBwoa7e03LMeta || {},
    alias: indexkBwoa7e03LMeta?.alias || [],
    redirect: indexkBwoa7e03LMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t39i6yPVrOMeta?.name ?? "online-degree-programs-graduate-slug",
    path: _91slug_93t39i6yPVrOMeta?.path ?? "/online-degree-programs/graduate/:slug()",
    meta: _91slug_93t39i6yPVrOMeta || {},
    alias: _91slug_93t39i6yPVrOMeta?.alias || [],
    redirect: _91slug_93t39i6yPVrOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/graduate/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexcoRP5dFWQRMeta?.name ?? "online-degree-programs-graduate",
    path: indexcoRP5dFWQRMeta?.path ?? "/online-degree-programs/graduate",
    meta: indexcoRP5dFWQRMeta || {},
    alias: indexcoRP5dFWQRMeta?.alias || [],
    redirect: indexcoRP5dFWQRMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/graduate/index.vue").then(m => m.default || m)
  },
  {
    name: indexqvAFqgMOE4Meta?.name ?? "online-degree-programs",
    path: indexqvAFqgMOE4Meta?.path ?? "/online-degree-programs",
    meta: indexqvAFqgMOE4Meta || {},
    alias: indexqvAFqgMOE4Meta?.alias || [],
    redirect: indexqvAFqgMOE4Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/index.vue").then(m => m.default || m)
  },
  {
    name: indexRAQIgCd4IXMeta?.name ?? "online-degree-programs-minors",
    path: indexRAQIgCd4IXMeta?.path ?? "/online-degree-programs/minors",
    meta: indexRAQIgCd4IXMeta || {},
    alias: indexRAQIgCd4IXMeta?.alias || [],
    redirect: indexRAQIgCd4IXMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/minors/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mLnLnl0iLrMeta?.name ?? "online-degree-programs-undergraduate-slug",
    path: _91slug_93mLnLnl0iLrMeta?.path ?? "/online-degree-programs/undergraduate/:slug()",
    meta: _91slug_93mLnLnl0iLrMeta || {},
    alias: _91slug_93mLnLnl0iLrMeta?.alias || [],
    redirect: _91slug_93mLnLnl0iLrMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/undergraduate/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXLHckAJI6OMeta?.name ?? "online-degree-programs-undergraduate",
    path: indexXLHckAJI6OMeta?.path ?? "/online-degree-programs/undergraduate",
    meta: indexXLHckAJI6OMeta || {},
    alias: indexXLHckAJI6OMeta?.alias || [],
    redirect: indexXLHckAJI6OMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/online-degree-programs/undergraduate/index.vue").then(m => m.default || m)
  },
  {
    name: communityArLvpvi0AdMeta?.name ?? "students-community",
    path: communityArLvpvi0AdMeta?.path ?? "/students/community",
    meta: communityArLvpvi0AdMeta || {},
    alias: communityArLvpvi0AdMeta?.alias || [],
    redirect: communityArLvpvi0AdMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/community.vue").then(m => m.default || m)
  },
  {
    name: coursesmAzLjdNISUMeta?.name ?? "students-courses",
    path: coursesmAzLjdNISUMeta?.path ?? "/students/courses",
    meta: coursesmAzLjdNISUMeta || {},
    alias: coursesmAzLjdNISUMeta?.alias || [],
    redirect: coursesmAzLjdNISUMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/courses.vue").then(m => m.default || m)
  },
  {
    name: graduation_45beyondCfMoXbzouOMeta?.name ?? "students-graduation-beyond",
    path: graduation_45beyondCfMoXbzouOMeta?.path ?? "/students/graduation-beyond",
    meta: graduation_45beyondCfMoXbzouOMeta || {},
    alias: graduation_45beyondCfMoXbzouOMeta?.alias || [],
    redirect: graduation_45beyondCfMoXbzouOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/graduation-beyond.vue").then(m => m.default || m)
  },
  {
    name: indexZEdnN4DVRLMeta?.name ?? "students",
    path: indexZEdnN4DVRLMeta?.path ?? "/students",
    meta: indexZEdnN4DVRLMeta || {},
    alias: indexZEdnN4DVRLMeta?.alias || [],
    redirect: indexZEdnN4DVRLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/index.vue").then(m => m.default || m)
  },
  {
    name: reviewsM0FNE2wRryMeta?.name ?? "students-reviews",
    path: reviewsM0FNE2wRryMeta?.path ?? "/students/reviews",
    meta: reviewsM0FNE2wRryMeta || {},
    alias: reviewsM0FNE2wRryMeta?.alias || [],
    redirect: reviewsM0FNE2wRryMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/reviews.vue").then(m => m.default || m)
  },
  {
    name: servicesjRrcVCCfmBMeta?.name ?? "students-services",
    path: servicesjRrcVCCfmBMeta?.path ?? "/students/services",
    meta: servicesjRrcVCCfmBMeta || {},
    alias: servicesjRrcVCCfmBMeta?.alias || [],
    redirect: servicesjRrcVCCfmBMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/services.vue").then(m => m.default || m)
  },
  {
    name: study_45abroadvq0nosFvNDMeta?.name ?? "students-study-abroad",
    path: study_45abroadvq0nosFvNDMeta?.path ?? "/students/study-abroad",
    meta: study_45abroadvq0nosFvNDMeta || {},
    alias: study_45abroadvq0nosFvNDMeta?.alias || [],
    redirect: study_45abroadvq0nosFvNDMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/study-abroad.vue").then(m => m.default || m)
  },
  {
    name: success_45team0SUSxxyfhEMeta?.name ?? "students-success-team",
    path: success_45team0SUSxxyfhEMeta?.path ?? "/students/success-team",
    meta: success_45team0SUSxxyfhEMeta || {},
    alias: success_45team0SUSxxyfhEMeta?.alias || [],
    redirect: success_45team0SUSxxyfhEMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/success-team.vue").then(m => m.default || m)
  },
  {
    name: tutoringv7JmwjBRHHMeta?.name ?? "students-tutoring",
    path: tutoringv7JmwjBRHHMeta?.path ?? "/students/tutoring",
    meta: tutoringv7JmwjBRHHMeta || {},
    alias: tutoringv7JmwjBRHHMeta?.alias || [],
    redirect: tutoringv7JmwjBRHHMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/students/tutoring.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HpEVrt151bMeta?.name ?? "study-slug",
    path: _91slug_93HpEVrt151bMeta?.path ?? "/study/:slug()",
    meta: _91slug_93HpEVrt151bMeta || {},
    alias: _91slug_93HpEVrt151bMeta?.alias || [],
    redirect: _91slug_93HpEVrt151bMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/study/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexD41gimTwCOMeta?.name ?? "study",
    path: indexD41gimTwCOMeta?.path ?? "/study",
    meta: indexD41gimTwCOMeta || {},
    alias: indexD41gimTwCOMeta?.alias || [],
    redirect: indexD41gimTwCOMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/study/index.vue").then(m => m.default || m)
  },
  {
    name: text_45termseuFnxRyT4tMeta?.name ?? "text-terms",
    path: text_45termseuFnxRyT4tMeta?.path ?? "/text-terms",
    meta: text_45termseuFnxRyT4tMeta || {},
    alias: text_45termseuFnxRyT4tMeta?.alias || [],
    redirect: text_45termseuFnxRyT4tMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/text-terms.vue").then(m => m.default || m)
  },
  {
    name: web_45analytics_45privacy_452M9Mov08huLMeta?.name ?? "web-analytics-privacy-2",
    path: web_45analytics_45privacy_452M9Mov08huLMeta?.path ?? "/web-analytics-privacy-2",
    meta: web_45analytics_45privacy_452M9Mov08huLMeta || {},
    alias: web_45analytics_45privacy_452M9Mov08huLMeta?.alias || [],
    redirect: web_45analytics_45privacy_452M9Mov08huLMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/web-analytics-privacy-2.vue").then(m => m.default || m)
  },
  {
    name: indexxzSZRj4HB7Meta?.name ?? "what-it-costs-financial-aid",
    path: indexxzSZRj4HB7Meta?.path ?? "/what-it-costs/financial-aid",
    meta: indexxzSZRj4HB7Meta || {},
    alias: indexxzSZRj4HB7Meta?.alias || [],
    redirect: indexxzSZRj4HB7Meta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/what-it-costs/financial-aid/index.vue").then(m => m.default || m)
  },
  {
    name: indexFHOrMoN10kMeta?.name ?? "what-it-costs",
    path: indexFHOrMoN10kMeta?.path ?? "/what-it-costs",
    meta: indexFHOrMoN10kMeta || {},
    alias: indexFHOrMoN10kMeta?.alias || [],
    redirect: indexFHOrMoN10kMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/what-it-costs/index.vue").then(m => m.default || m)
  },
  {
    name: indexRjeqvkbJpmMeta?.name ?? "what-it-costs-scholarship-opportunities",
    path: indexRjeqvkbJpmMeta?.path ?? "/what-it-costs/scholarship-opportunities",
    meta: indexRjeqvkbJpmMeta || {},
    alias: indexRjeqvkbJpmMeta?.alias || [],
    redirect: indexRjeqvkbJpmMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/what-it-costs/scholarship-opportunities/index.vue").then(m => m.default || m)
  }
]