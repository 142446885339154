"use strict";

import axios, { AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import {
  Category,
  DegreeSeekingProgram,
} from "~/server/resolvers/resolvers-types";
import { RfiFormFieldShape } from "~/src/services/rfiSchema";
import {
  BriteVerifyResponseData,
  FormattedProgram,
  RelationalFormattedProgram,
  WithRequired,
} from "~/types/types";

export const useRfiService = () => {
  const config = useRuntimeConfig();

  const rfiClient = axios.create({
    baseURL: config.public.leadApiBaseUrl,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  axiosRetry(rfiClient, {
    retries: 3,
    retryDelay: (retryCount) => {
      return retryCount * 1000;
    },
  });

  const briteverifyClient = axios.create({
    baseURL: config.public.briteverifyUrl,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `ApiKey: ${config.public.briteverifyApiKey}`,
    },
  });

  // internal functions

  interface AsuoCookieData {
    utm_source: string | null;
    utm_medium: string | null;
    utm_term: string | null;
    utm_content: string | null;
    utm_campaign: string | null;
    utm_campaignid: string | null;
    utm_adid: string | null;
    utm_adgroupid: string | null;
    psfcid: string | null;
    gclickid: string | null;
    gclsrc: string | null;
  }
  /**
   * Gets data from the ASUO cookie.
   */
  function getASUOCookie(): AsuoCookieData {
    const asuo = getCookie("asuo");
    var result: AsuoCookieData = {
      utm_source: null,
      utm_medium: null,
      utm_term: null,
      utm_content: null,
      utm_campaign: null,
      utm_campaignid: null,
      utm_adid: null,
      utm_adgroupid: null,
      psfcid: null,
      gclickid: null,
      gclsrc: null,
    };

    if (asuo !== null) {
      const decodedCookie = globalThis.decodeURIComponent(asuo);
      const parsedArray = decodedCookie.split("|");
      let index = 0;

      parsedArray.forEach((item) => {
        index = item.indexOf("=");
        result[item.substring(0, index) as keyof AsuoCookieData] =
          item.substring(index + 1);
      });
    }

    return result;
  }

  interface GtmClientIds {
    clientid: string;
    enterpriseclientid: string;
  }

  function getGtmClientIds(): GtmClientIds {
    const gaCookie = getCookie("_ga");
    let gaClientId = null;

    try {
      if (gaCookie && typeof gaCookie !== "undefined") {
        // sample value: GA1.1.2040707708.1641428898
        gaClientId = gaCookie.split(".").splice(2).join(".").toString();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("== error in getting client IDs: ", error);
    } finally {
      return {
        clientid: gaClientId!,
        enterpriseclientid: gaClientId!,
      };
    }
  }

  /**
   * Returns GA CLient ID
   * sample value: GA1.1.2040707708.1641428898
   */
  function getGaClientId(): string {
    const gaCookie = getCookie("_ga");
    let gaClientId = "";

    try {
      if (gaCookie && typeof gaCookie !== "undefined") {
        // sample value: GA1.1.2040707708.1641428898
        gaClientId = gaCookie.split(".").splice(2).join(".").toString();
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error("== error in getting client IDs: ", error);
    } finally {
      return gaClientId;
    }
  }

  /**
   * Gets cookie using cookie's name
   * Example:
   * getCookie("_ga")
   */
  function getCookie(cname: string): string | null {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  /**
   * sets name and program code in session storage
   * @param {{name: string, programCode: string}} myObj student name and program code
   */
  function setStudentSessionInfo({
    name,
    programCode,
  }: {
    name: string;
    programCode: string;
  }) {
    sessionStorage.setItem("rfi-name", name);
    sessionStorage.setItem("rfi-program", programCode);
  }

  // exported functions

  /**
   * Maps all programs to an object
   */
  function generateAllProgramMap(programs: DegreeSeekingProgram[]) {
    const map: { [key: string]: DegreeSeekingProgram } = {};

    programs.forEach((program) => {
      map[program.id] = program;
    });

    return map;
  }

  function getAllFormattedPrograms(programs: DegreeSeekingProgram[]) {
    const result: WithRequired<FormattedProgram, "interestAreas">[] = [];

    programs.forEach((program) => {
      const areas: string[] = [];
      program.interestAreas!.forEach((area) => {
        areas.push(area!.title!);
      });
      result.push({
        id: program.id,
        code: program.code!,
        title: program.title!,
        category: program.category?.title!,
        interestAreas: areas,
      });
    });
    return result;
  }

  function getAllFormattedInterestAreas(
    interestAreaEdges: Array<Pick<DegreeSeekingProgram, "title">>
  ) {
    if (interestAreaEdges && interestAreaEdges.length > 0) {
      return interestAreaEdges.map((item) => item.title!);
    } else {
      return [""];
    }
  }

  function getAllFormattedCategories(
    categorys: Array<Pick<Category, "title">>
  ): string[] {
    return categorys.map((item) => item.title!);
  }

  function getDegreeTypePrograms(
    allPrograms: WithRequired<FormattedProgram, "interestAreas">[],
    degreeType: string
  ) {
    return allPrograms.filter((item) => item.category === degreeType);
  }
  function getDegreeTypeInterestAreas(
    allPrograms: Array<WithRequired<FormattedProgram, "interestAreas">>,
    degreeType: string
  ): string[] {
    /**
     * @type {string[]}
     */
    const result: string[] = [];
    const degreeTypePrograms = getDegreeTypePrograms(allPrograms, degreeType);
    degreeTypePrograms.forEach((item) => {
      // interestAreas is an array of string
      item.interestAreas.forEach((area) => {
        if (result.indexOf(area) === -1) {
          result.push(area);
        }
      });
    });
    return result;
  }

  function getInterestAreaPrograms(
    allPrograms: Array<WithRequired<FormattedProgram, "interestAreas">>,
    interestArea: string
  ) {
    return allPrograms.filter((item) => {
      return item.interestAreas.indexOf(interestArea) > -1;
    });
  }

  /**
   *
   * @param allDegreeTypes - array of degree types as strings
   * @param  allPrograms - array of formatted programs
   * @returns  Returns an object of degree types with objects of interest areas and their associated formatted programs.
   */
  function getRelationalProgramData(
    allDegreeTypes: string[],
    allPrograms: Array<WithRequired<FormattedProgram, "interestAreas">>
  ): RelationalFormattedProgram {
    const relationalData: RelationalFormattedProgram = {};

    allDegreeTypes.forEach((type) => {
      relationalData[type as keyof RelationalFormattedProgram] = {};
    });

    Object.keys(relationalData).forEach((key) => {
      const areas = getDegreeTypeInterestAreas(allPrograms, key);

      areas.forEach((area) => {
        relationalData[key][area] = [];
      });
    });

    allPrograms.forEach((program) => {
      program.interestAreas.forEach((interestArea) => {
        relationalData[program.category][interestArea].push(program);
      });
    });
    return relationalData;
  }

  function getDegreeTypeOptions(degreeTypes: string[]) {
    const options: Array<{ value: string; text: string }> = [];
    // Make sure the values are exactly the same as the keys else the values will be duplicated in the options
    const degreeOrder = ["Undergraduate", "Graduate", "Certificates"];

    const orderedDegreeTypeSet = new Set(degreeOrder);
    const orderedDegreeTypes = [
      ...degreeOrder,
      ...degreeTypes.filter((type) => !orderedDegreeTypeSet.has(type)),
    ];
    orderedDegreeTypes.forEach((item) => {
      options.push({
        value: item,
        text: item,
      });
    });
    return options;
  }

  /**
   * @param  interestAreas - string array of interest areas
   */
  function getInterestAreaOptions(interestAreas: string[]) {
    const options: Array<{ value: string; text: string }> = [];
    interestAreas.forEach((item) => {
      options.push({
        value: item,
        text: item,
      });
    });
    return options;
  }

  /**
   * @param  programs - string array of program names
   */
  function getProgramOptions(programs: FormattedProgram[]) {
    const options: Array<{ value: string; text: string }> = [];
    programs.forEach((item) => {
      options.push({
        value: item.id,
        text: item.title,
      });
    });

    return options;
  }

  function getProgramNameFromCode(
    allPrograms: FormattedProgram[],
    programCode: string
  ): string | "Select a program" {
    const result = allPrograms.filter((program) => {
      return program.code === programCode;
    });
    if (result.length > 0) {
      return result[0].title;
    }
    return "Select a program";
  }

  function getProgramCode(
    allPrograms: FormattedProgram[],
    programId: string
  ): string | "N/A" {
    const result = allPrograms.filter((program) => {
      return program.id === programId;
    });
    if (result.length > 0) {
      return result[0].code;
    }
    return "N/A";
  }

  function getUrlParams(url: string) {
    const params: { [key: string]: string | number } = {};

    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      return (params[key] = value);
    });

    return params;
  }

  /**
   * Generates Form tracking data for GA Datalayer
   * @param  section
   * @param  text
   * @param   form - RFI form data
   * @param  career
   * @param  programName
   * @param  programCode
   */
  function getFormTrackingData(
    section: string,
    text: string,
    form: RfiFormFieldShape,
    career: string,
    programName: string,
    programCode: string
  ) {
    return {
      event: "form",
      name: "onclick",
      action: "click",
      type: "click",
      region: "main content",
      section: section,
      text: text,
      first_name: form.firstName!.toLowerCase(),
      last_name: form.lastName!.toLowerCase(),
      email_address: form.email!.toLowerCase(),
      phone: form.phone.formattedNumber,
      career: career.toLowerCase(),
      program: programName.toLowerCase(),
      program_code: programCode,
      military_service: form.isMilitary === false ? "no" : "yes",
    };
  }
  /**
   * Generates Form tracking data for GA Datalayer, specific to forms with military checkboxes.
   * @param  section
   * @param  text
   * @param  form - RFI form data
   * @param  career
   * @param  programName
   * @param  programCode
   * @param  isMilitary
   */
  function getMilitaryFormTrackingData(
    section: string,
    text: string,
    form: RfiFormFieldShape,
    career: string,
    programName: string,
    programCode: string,
    isMilitary: boolean
  ) {
    return {
      event: "form",
      name: "onclick",
      action: "click",
      type: "click",
      region: "main content",
      section: section,
      text: text,
      first_name: form.firstName!.toLowerCase(),
      last_name: form.lastName!.toLowerCase(),
      email_address: form.email!.toLowerCase(),
      phone: form.phone.formattedNumber,
      career: career.toLowerCase(),
      program: programName.toLowerCase(),
      program_code: programCode,
      military_service: isMilitary,
    };
  }

  function formatPhoneNumber(phone: string) {
    // removing the `+`, then removing the spaces
    return phone ? phone.slice(1).replace(/ /g, "") : null;
  }
  /**
   * Generates Lead Data for Military forms that are sent to the Lead API
   * @param  form - RFI form data
   * @param programCode
   */
  function getMilitaryLeadData(form: RfiFormFieldShape, programCode: string) {
    const gtmData = getGtmClientIds();
    const asuoCookie = getASUOCookie();

    setStudentSessionInfo({ name: form.firstName!, programCode: programCode });

    return {
      first_name: form.firstName,
      last_name: form.lastName,
      email_address: form.email,
      phone: formatPhoneNumber(form.phone.formattedNumber!),
      program_key: programCode,
      origin_uri: window ? window.location.toString() : "",
      sourceid: config.public.leadSourceId,
      sms_permission: true,
      utm_ecd22: getCookie("utm_ecd22"),
      ...gtmData,
      ...asuoCookie,
      military_service: form.isMilitary,
    };
  }

  /**
   * Passes Lead Data up to lead api
   */
  function addRFILead(leadData: ReturnType<typeof getMilitaryLeadData>) {
    if (config.public.leadApiPath) {
      return rfiClient.post(config.public.leadApiPath, leadData);
    } else {
      console.error("Lead API Path Env Variable Missing");
    }
  }

  /**
   * Sends email address and phone number ( if from US or CA ) to briteverify and sends back verification response object
   * @param {string} phone
   * @param {string} email
   * @param {boolean} isUsOrCanada
   * @returns {Promise<import("axios").AxiosResponse<import("types/types").BriteVerifyResponseData>>} - Promise containing brite verify response data
   */
  function getBriteverifyResponse(
    phone: string,
    email: string,
    isUsOrCanada: boolean
  ): Promise<AxiosResponse<BriteVerifyResponseData>> {
    let verificationData: { email: string; phone?: string } = { email: email };

    // if phone number is US or CA country code, add phone
    if (isUsOrCanada) {
      verificationData.phone = phone;
    }

    return briteverifyClient.post("/fullverify", verificationData);
  }

  /**
   * Takes Briteverify Axios response and verifies if phone is valid.
   * Requires `phone` property to be present in `BriteVerifyResponseData`
   */
  function isVerifiedPhone(
    responseData: AxiosResponse<BriteVerifyResponseData>,
    isUsOrCanada: boolean
  ) {
    if (!isUsOrCanada) {
      return true;
    } else if (
      responseData.data &&
      responseData.data.phone &&
      responseData.data.phone.status
    ) {
      return responseData.data.phone.status == "valid" ||
        responseData.data.phone.status == "accept_all"
        ? true
        : false;
    }

    return false;
  }

  /**
   * Takes Briteverify Axios response and verifies if emai is valid and not disposable.
   * Requires `email` property to be present in `BriteVerifyResponseData`
   */
  function isVerifiedEmail(
    responseData: AxiosResponse<BriteVerifyResponseData>
  ) {
    // only return true if email meets the following criteria:
    // status is 'valid' or 'accept_all'; disposable does not equal true
    if (
      responseData.data &&
      responseData.data.email &&
      responseData.data.email.status
    ) {
      return (responseData.data.email.status == "valid" ||
        responseData.data.email.status == "accept_all") &&
        responseData.data.email.disposable !== true
        ? true
        : false;
    }

    return false;
  }

  /**
   * Gets the career value for a specific program code from a list of programs.
   * @param {string} programCode - The program code to search for.
   * @param {Array<FormattedProgram>} programs - The list of programs to search through.
   * @returns {string | null} - The career value associated with the program code, or null if not found.
   */
  function getCareerValueByProgramCode(
    programCode: string,
    programs: FormattedProgram[]
  ): string | null {
    const program = programs.find((p) => p.code === programCode);
    return program && program.category !== undefined ? program.category : null;
  }

  return {
    addRFILead: addRFILead,
    getFormTrackingData: getFormTrackingData,
    getGtmClientIds: getGtmClientIds,
    getUrlParams: getUrlParams,
    getProgramCode: getProgramCode,
    getProgramNameFromCode: getProgramNameFromCode,
    getProgramOptions: getProgramOptions,
    getInterestAreaOptions: getInterestAreaOptions,
    getDegreeTypeOptions: getDegreeTypeOptions,
    getRelationalProgramData: getRelationalProgramData,
    getInterestAreaPrograms: getInterestAreaPrograms,
    getDegreeTypeInterestAreas: getDegreeTypeInterestAreas,
    getDegreeTypePrograms: getDegreeTypePrograms,
    getAllFormattedCategories: getAllFormattedCategories,
    getAllFormattedInterestAreas: getAllFormattedInterestAreas,
    getAllFormattedPrograms: getAllFormattedPrograms,
    generateAllProgramMap: generateAllProgramMap,
    getMilitaryFormTrackingData: getMilitaryFormTrackingData,
    getMilitaryLeadData: getMilitaryLeadData,
    isVerifiedPhone: isVerifiedPhone,
    isVerifiedEmail: isVerifiedEmail,
    getBriteverifyResponse: getBriteverifyResponse,
    getGaClientId: getGaClientId,
    getCareerValueByProgramCode: getCareerValueByProgramCode,
  };
};
