import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/vue";
import { withScope } from "@sentry/vue";
import type { Router } from "vue-router";

// https://github.com/nuxt-community/sentry-module/issues/530
export default defineNuxtPlugin({
  parallel: true,
  setup: (nuxtApp) => {
    Sentry.init({
      app: nuxtApp.vueApp,
      autoSessionTracking: true,
      dsn: nuxtApp.$config.public.sentryDsn,
      release: nuxtApp.$config.public.siteVersion,
      environment: nuxtApp.$config.public.siteEnv,
      integrations: [
        Sentry.browserTracingIntegration({
          router: nuxtApp.$router as Router,
          routeLabel: "path",
        }),
        // Sentry.replayIntegration({
        //   networkDetailAllowUrls: [
        //     `https//${nuxtApp.$config.public.HOST_NAME}`,
        //   ],
        // }),
        // Sentry.httpClientIntegration(),
        // Sentry.reportingObserverIntegration(),
      ],
      trackComponents: true,
      hooks: ["activate", "create", "destroy", "mount", "update"],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      // ignoreTransactions: ["Vue <<Anonymous>>"],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });

    nuxtApp.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra("context", context);
        Sentry.captureException(err);
      });
    };

    nuxtApp.hook("app:error", (err) => {
      Sentry.captureException(err);
    });

    return {
      provide: {
        sentrySetContext: Sentry.setContext,
        sentrySetUser: Sentry.setUser,
        sentrySetTag: Sentry.setTag,
        sentryAddBreadcrumb: Sentry.addBreadcrumb,
        sentryCaptureException: Sentry.captureException,
      },
    };
  },
});
