// export functions

// reference: https://www.30secondsofcode.org/blog/s/javascript-array-comparison
// Unused currently, potentially remove
// function equalsIgnoreOrder(a, b) {
//   if (a.length !== b.length) {
//     return false;
//   }

//   const uniqueValues = new Set([...a, ...b]);

//   for (const v of uniqueValues) {
//     const aCount = a.filter((e) => e === v).length;
//     const bCount = b.filter((e) => e === v).length;

//     if (aCount !== bCount) {
//       return false;
//     }
//   }

//   return true;
// }

export const useUtilityService = () => {
  const config = useRuntimeConfig();

  function sortByText(a: any, b: any) {
    let titleA = a.text.toUpperCase();
    let titleB = b.text.toUpperCase();

    if (titleA < titleB) {
      return -1;
    }

    if (titleA > titleB) {
      return 1;
    }

    // titles must be equal
    return 0;
  }

  // Unused currently, potentially remove
  // function getLeadApiBaseUrl() {
  //   let baseUrl = LEAD_API_QA;

  //   if (process.isClient) {
  //     const subdomainName = window.location.host.split(".")[0];

  //     if (subdomainName === PROD_CAPTION) {
  //       baseUrl = LEAD_API_PROD;
  //     }
  //   }

  //   return baseUrl;
  // }

  /** @param {string} pageUrl*/
  function formatCanonicalUrl(pageUrl: string) {
    return pageUrl.replace(/([^:]\/)\/+/g, "$1");
  }
  /** @param {string} categorySlug*/
  function isUndergraduateProgram(categorySlug: string) {
    return categorySlug === "undergraduate";
  }
  /** @param {string} categorySlug*/
  function isGraduateProgram(categorySlug: string) {
    return categorySlug === "graduate";
  }
  /** @param {string} categorySlug*/
  function isCertificatesProgram(categorySlug: string) {
    return categorySlug === "certificates";
  }

  /**
   * @param {string} categorySlug
   * @param {string} certificateType
   */
  function isCertificatesUndergradProgram(
    categorySlug: string,
    certificateType: string
  ) {
    return (
      isCertificatesProgram(categorySlug) && certificateType === "undergrad"
    );
  }
  /**
   * @param {string} categorySlug
   * @param {string} certificateType
   */
  function isCertificatesGraduateProgram(
    categorySlug: string,
    certificateType: string
  ) {
    return (
      isCertificatesProgram(categorySlug) && certificateType === "graduate"
    );
  }
  /**
   * @param {string} date - date string
   */
  function formatDate(date: string) {
    const dateValues = date.split("-");
    const newDate = new Date(
      parseInt(dateValues[0]),
      parseInt(dateValues[1]) - 1,
      parseInt(dateValues[2])
    );
    const monthText = newDate.toLocaleString("default", { month: "long" });
    const dateText =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
    const yearText = newDate.getFullYear();
    return `${monthText} ${dateText}, ${yearText}`;
  }
  /**
   * Generates Social Share Links for Facebook, Twitter, Linkedin and email
   * @param {string} iconTitle
   * @param {string} detailPage
   * @param {string} articleTitle
   */
  function generateSocialShareLink(
    iconTitle: string,
    detailPage: string,
    articleTitle: string
  ) {
    var pageUrl = config.public.siteUrl + detailPage;
    var shareLink = "#";
    switch (iconTitle) {
      case "facebook":
        shareLink = "https://www.facebook.com/sharer/sharer.php?u=" + pageUrl;
        break;
      case "twitter":
        shareLink =
          "https://www.twitter.com/share?url=" +
          pageUrl +
          "&text=" +
          articleTitle;
        break;
      case "linkedin":
        shareLink = "https://www.linkedin.com/shareArticle?url=" + pageUrl;
        break;
      case "mail":
        shareLink =
          "mailto:?subject=Check this out:" + articleTitle + "&body=" + pageUrl;
        break;
      default:
        break;
    }
    return shareLink;
  }
  /** @param {string} target */
  function formatLinkTarget(target: string) {
    if (target === "BLNK") return "_blank";
    else return "_self";
  }

  /**
   * Generates Social Media Metadata for pages
   * @param {string} title
   * @param {string} description
   * @param {string} image - src
   */
  const generateSocialTags = (
    title: string,
    description: string,
    image?: string
  ) => {
    let cardImageURL = config.public.siteUrl + "/asuonline-thumbnail-logo.jpg";
    if (image) {
      cardImageURL = image;
    }

    let tagsList = [
      {
        key: "og:title",
        property: "og:title",
        content: title,
      },
      {
        key: "og:description",
        property: "og:description",
        content: description,
      },
      {
        name: "twitter:title",
        content: title,
      },
      {
        name: "twitter:description",
        content: description,
      },
      {
        key: "og:image",
        property: "og:image",
        content: cardImageURL,
      },
      {
        name: "twitter:image",
        content: cardImageURL,
      },
      {
        name: "twitter:card",
        content: "summary",
      },
    ];

    return tagsList;
  };

  const usePadTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };
  const formatWebinarDate = (date: string, type: string) => {
    if (type === "date") {
      const dateArray = [
        usePadTo2Digits(new Date(date).getMonth() + 1),
        usePadTo2Digits(new Date(date).getDate()),
        new Date(date).getFullYear(),
      ];
      return dateArray.join("/");
    } else {
      let time = new Date(date).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      if (time.includes("AM")) {
        time = time.replace("AM", "a.m.");
      } else {
        time = time.replace("PM", "p.m.");
      }
      return time;
    }
  };

  return {
    formatCanonicalUrl: formatCanonicalUrl,
    sortByText: sortByText,
    isUndergraduateProgram: isUndergraduateProgram,
    isGraduateProgram: isGraduateProgram,
    isCertificatesProgram: isCertificatesProgram,
    isCertificatesUndergradProgram: isCertificatesUndergradProgram,
    isCertificatesGraduateProgram: isCertificatesGraduateProgram,
    formatDate: formatDate,
    generateSocialShareLink: generateSocialShareLink,
    formatLinkTarget: formatLinkTarget,
    generateSocialTags: generateSocialTags,
    formatWebinarDate: formatWebinarDate,
  };
};
